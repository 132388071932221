<template>
  <div>
    <b-container fluid class="content quicknotes">
      <b-alert
        :show="countdown"
        dismissible
        variant="success"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4">
        <b-col>
          <b-card>
            <b-row>
              <b-col cols="12">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:QuickNotesEdit.enable.show
                  >
                  {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button type="primary" @click="disableSort"
                    v-acl:QuickNotesEdit.enable.show
                  >
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3 p-2 border bg-secondary">
              <b-col>
                {{ $t('Name')+'1' }}
                <el-input
                  :label="$t('Name')+'1'"
                  class="p-0 m-0"
                  size="mini"
                  v-model="InputName1" />
              </b-col>
              <b-col>
                {{ $t('Name')+'2' }}
                <el-input
                  :label="$t('Name')+'2'"
                  class="p-0 m-0"
                  size="mini"
                  v-model="InputName2" />
              </b-col>
              <b-col>
                <el-button type="primary" class="mt-4" size="mini" @click="add_notes()">{{ $t('Add') }}</el-button>
              </b-col>
            </b-row>
            <p class="p-3">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="quicknoteslist"
                ref="dragTable"
                row-key="IDn"
                style="width: 100%;"
              >
                <el-table-column
                  :label="$t('Name')+'1'"
                  prop="Name1"
                  :min-width="40"
                >
                  <template slot-scope="scope">
                    <el-input
                      class="p-0 m-0"
                      size="mini"
                      v-model="scope.row.Name1"
                      @change="update_notes(scope.row)"
                      v-acl:QuickNotesEdit.enable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Name')+'2'"
                  prop="Name2"
                  :min-width="40"
                >
                  <template slot-scope="scope">
                    <el-input
                      class="p-0 m-0"
                      size="mini"
                      v-model="scope.row.Name2"
                      @change="update_notes(scope.row)"
                      v-acl:QuickNotesEdit.enable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="20"
                  v-if="hasUtilityAuth('QuickNotesEdit')"
                >
                  <template slot-scope="scope">
                    <div class="w-100 p-1 text-center title2" @click="remove_notes(scope.row.IDn)" v-acl:QuickNotesEdit.enable.show>
                      <el-tooltip
                        effect="light"
                        content="Delete Notes"
                        placement="top"
                        style="margin: auto"
                        :openDelay="300"
                      >
                        <i class="ni ni-fat-remove icon-remove"></i>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
      <el-dialog
        :title="$t('Please Confirm Delete')"
        :visible.sync="confirmDialog"
        width="30%"
        center>
        <h1 class="text-danger text-center">{{ $t('Deletion cannot be undone after you confirm') }}</h1>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirmDialog = false" class="mr-6">{{ $t('Cancel') }}</el-button>
          <el-button type="primary" @click="deleteConfirmed" class="ml-6">{{ $t('Confirm') }}</el-button>
        </span>
      </el-dialog>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Row, Col, Dropdown, Dialog } from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      InputName1: "",
      InputName2: "",
      quicknoteslist: [],

      sortable: null,
      isSortableEnabled: false,

      dismessage: "",
      countdown: 0,
      confirmDialog: false,

      token: "",
      user_id: 0,
    };
  },
  methods: {
    hasUtilityAuth,
    handleCommand(selected) {
      this.per_page = selected;
      return this.get_quicknotes_list();
    },
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
      }
    },
    enableSort() {
      if (this.quicknoteslist.length <= 1) {
        return;
      }
      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.quicknoteslist);
          const targetRow = this.quicknoteslist.splice(evt.oldIndex, 1)[0];
          this.quicknoteslist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.quicknoteslist);
        },
      });
    },
    async deleteConfirmed() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("IDn", this.cur_quicknotes.IDn);
      var that = this;
      this.confirmDialog = false
      
      axios({
        method: "post",
        url: "/Api/Web/quicknotes/delete",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              that.get_quicknotes_list();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.quicknoteslist.length; i++){
        orderlist.push({IDn:this.quicknoteslist[i].IDn, ApplyOrder:i+1});
      }
      bodyFormData.append("ApplyOrderList", JSON.stringify(orderlist));

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/quicknotes/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.quicknoteslist = [];
              that.$nextTick(() => {
                that.quicknoteslist = rt.data.quicknotes;
              });
              // that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async remove_notes(IDn) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "delete");

      bodyFormData.append("IDn", IDn);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/quicknotes/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.quicknoteslist = [];
              that.$nextTick(() => {
                that.quicknoteslist = rt.data.quicknotes;
              });
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async update_notes(row) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");

      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("Name1", row.Name1);
      bodyFormData.append("Name2", row.Name2);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/quicknotes/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.quicknoteslist = [];
              that.$nextTick(() => {
                that.quicknoteslist = rt.data.quicknotes;
              });
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async add_notes() {
      if ((this.InputName1.length < 1) || (this.InputName2.length < 1)) {
        alert("Please input both Name1 and Name2")
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "add");

      bodyFormData.append("IDn", 0);
      bodyFormData.append("Name1", this.InputName1);
      bodyFormData.append("Name2", this.InputName2);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/quicknotes/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.quicknoteslist = [];
              that.$nextTick(() => {
                that.quicknoteslist = rt.data.quicknotes;
              });
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_quicknotes_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/quicknotes",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.quicknoteslist = [];
              that.$nextTick(() => {
                that.quicknoteslist = rt.data.quicknotes;
              });
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_quicknotes_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
.handshape{
  cursor: pointer;
}
.quicknotes .el-table tr td {
  padding-top: 0;
  padding-bottom: 0;
}
.icon-add {
  color: #808080;
  background-color: rgba(0, 255, 0, 0.5);
  border-radius: 50%;
}
.icon-remove {
  color: #404040;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
}
</style>
